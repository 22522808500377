import { makeStyles, Typography, Divider, useTheme, useMediaQuery} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),

  },
  header: {
    marginTop: theme.spacing(1),
  },
  subtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  donorEntry: {
    marginBottom: '20px',
    // border: "solid 1px #909090",
    // padding: theme.spacing(2),
    // borderRadius: theme.spacing(2),
  },
  donorMessage: {
    fontSize: theme.typography.pxToRem(18),
    paddingBottom: '5px',
    fontStyle: "italic",
  },
  donorInfoContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: '5px'
  },
  donorName: {
    fontSize: theme.typography.pxToRem(16),
    color: "#777777",
  },
  donorAmount: {
    fontSize: theme.typography.pxToRem(16),
    color: "#0466ef",
  }
}));

const DonorEntry = (props) => {
  const entry = props.entry;
  const classes = props.classes;

  return (
    <div className={classes.donorEntry}>
      <Typography className={classes.donorMessage}>{entry.message}</Typography>
      <div className={classes.donorInfoContainer}>
        <Typography className={classes.donorName}>{entry.name || "Anonymous"}</Typography>
        <Typography className={classes.donorAmount}>R{entry.amount.toLocaleString("en-US", { minimumFractionDigits: 2 })}</Typography>

      </div>
      <Divider/>
    </div>
  );
};

const DonorList = (props) => {
  const classes = useStyles(props);
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  // const multiple = 0; // for testing to inflate the list

  const perPage = 5;

  let donorList = [...props.donorList];
  // for (let i = 0; i < multiple; i++) {
  //   donorList = [...donorList, ...props.donorList.map(item=>({...item, amount: item.amount*i}))];
  // }

  const currentlyShownItems = [];
  for (let i = (page - 1)*perPage; i < page*perPage; i++) {
    if (donorList[i]) {
      currentlyShownItems.push(donorList[i]);
    }
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  return (
    <div className={classes.container}>
      <h2 variant="h2" className={classes.header}>Thank you</h2>
      <h4 variant="h5" className={classes.subtitle}>
        Your kindness and your voice is appreciated.
      </h4>

      <div className={classes.donorContainer}>
        {currentlyShownItems.map(donor => (
          <DonorEntry entry={donor} classes={classes} />
        ))}
      </div>
      <Pagination size={isMobile ? "small" : "medium"} count={1 + (Math.floor(donorList.length / perPage))} shape="rounded" onChange={handleChange} />
    </div>

  );
}

export default DonorList;