import React from "react";
import {
  Toolbar as MaterialToolbar,
  Link,
  AppBar,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    toolbarContainer: {
      zIndex: "1000",
      background: theme.palette.primary.main,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-evenly",
    },
    toolbarLink: {
      color: theme.palette.primary.contrastText,
      fontWeight: 500,
    },
  };
});

const Toolbar = (props) => {
  const classes = useStyles(props);

  const navLinks = [
    { text: "Donate", url: "#donation", onClick: props.handleShowForm },
    { text: "Apply", url: "https://kandua.typeform.com/to/u7B94KK1" },
    { text: "Share", url: "#share", onClick: props.handleShowShare },
    { text: "How it works", url: "#how", display: { xs: "none", md: "block" } },
  ].map((info, index) => {
    return (
      <Box display={info.display}>
        <Link
          variant="h6"
          key={index + "_" + info.text}
          href={info.url}
          onClick={info.onClick}
          display={"none"}
          className={classes.toolbarLink}
        >
          {info.text}
        </Link>
      </Box>
    );
  });

  return (
    <AppBar>
      <MaterialToolbar className={classes.toolbarContainer} variant={"dense"}>
        {navLinks}
      </MaterialToolbar>
    </AppBar>
  );
};

export default Toolbar;
