import {
  makeStyles,
  Grid,
  Box,
  Button,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import SuperAgent from "superagent";
import qs from "qs";

import DonateButton from "./components/DonateButton";
import DonateProgress from "./components/DonateProgress";
import Toolbar from "./components/Toolbar";
import DonorList from "./components/DonorList";
import ShareDialog from "./components/ShareDialog";

const useStyles = makeStyles((theme) => {
  return {
    introLine: {
      textAlign: "center",
    },
    contentPiece: {
      width: "80%",
    },
    html: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      alignContent: "center",
    },
    header: {
      width: "100%",
      textAlign: "center",
    },
    headerImage: {
      backgroundImage: 'url("/joburg.jpg")',
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top -40px center",
      width: "100%",
      objectFit: "cover",
      height: "300px",
      textAlign: "center",
    },
    logo: {
      height: "100px",
      //width: "60%",
      maxWidth: "800px",
      marginTop: "70px",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      maxWidth: "1000px",
      // width: '100%',
      padding: theme.spacing(3),
      textAlign: "left",
      // '@font-face': [poppins],
    },
    bodyText: {},
    content: {
      padding: theme.spacing(5),
      width: "100%",
    },
    contentColumn: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    point: {
      display: "flex",
      flexDirection: "row",
    },
    pointIcon: {
      width: "50px",
    },
    pointIconParent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    pointText: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    detailsHeading: {
      marginBottom: "5px",
    },
    donationsBox: {
      borderRadius: "2px",
    },
    apply: {
      flexGrow: 0,
      width: "300px",
      // backgroundColor: "#4c95fc",
      boxShadow:
        "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
    },
    helpLink: {
      color: "#4c95fc",
      fontWeight: "600",
    },
    example: {
      fontWeight: 200,
      color: "#333333",
    },
    criterion: {
      marginBottom: "8px",
    },
    criteria: {
      marginBottom: "100px",
    },
  };
});

function App(props) {
  const theme = useTheme();

  const classes = useStyles(props);
  const [donorList, setDonorList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showShare, setShowShare] = useState(false);

  const handleShowForm = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setShowForm(true);
  };

  const handleShowShare = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setShowShare(true);
  };

  useEffect(() => {
    SuperAgent.get("https://rebuild-api.kandua.com/donations")
      .then((res) => {
        const { body } = res;
        setDonorList(body);
      })
      .catch((err) => {});
  }, []);

  let total = 0;
  donorList.forEach((donor) => (total += donor.amount));

  console.log("TOTAL", total);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={classes.html}>
      <header className={classes.header}>
        <Toolbar
          handleShowForm={handleShowForm}
          handleShowShare={handleShowShare}
        />
      </header>
      <div className={classes.body}>
        {/* <Typography className={classes.introLine} variant="h5" align="center" style={{ width: "100%" }}>
          Let's start with the damaged buildings. <br/>
        </Typography> */}
      </div>
      <div
        className={classes.headerImage}
        style={{
          ...(isMobile
            ? {
                height: "200px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }
            : {}),
        }}
      >
        <img
          src="/Kandua.png"
          className={classes.logo}
          style={{
            ...(isMobile
              ? {
                  height: "70px",
                  marginTop: "-80px",
                }
              : {}),
          }}
          alt="logo"
        />
      </div>
      <div>
        <div className={classes.body}>
          <DonateButton
            showForm={showForm}
            showShare={showShare}
            setShowForm={setShowForm}
          />
          <ShareDialog showDialog={showShare} setShowDialog={setShowShare} />
          <Box
            boxShadow={1}
            className={classes.donationsBox}
            style={
              isMobile
                ? {
                    marginTop: "-50px",
                  }
                : {}
            }
          >
            <DonateProgress amount={total} />
            <DonorList donorList={donorList} />
          </Box>
          {/* <Grid container spacing={2} className={classes.content}> */}
          {/* <Grid item xs className={classes.contentColumn}> */}

          {/* </Grid> */}
          {/* <Grid item xs className={classes.contentColumn}> */}
          {/* </Grid> */}
          {/* </Grid> */}

          <h2 id="how"> How do we begin to #rebuildSA? </h2>

          <div className={classes.howItWorks}>
            <Grid container xl={12} spacing={1} className={classes.point}>
              <Grid
                item
                xl={2}
                className={classes.pointIconParent}
                alignItems={"right"}
              >
                <img
                  height="px"
                  src="/points/work.png"
                  className={classes.pointIcon}
                  alt="work"
                />
              </Grid>
              <Grid
                item
                xl={10}
                alignItems={"center"}
                className={classes.pointText}
              >
                <span>
                  Service providers get to work, rebuilding damaged buildings in
                  their communities.
                </span>
              </Grid>
            </Grid>
            <Grid container xl={12} spacing={1} className={classes.point}>
              <Grid
                item
                xl={2}
                className={classes.pointIconParent}
                alignItems={"right"}
              >
                <img
                  height="px"
                  src="/points/donate.png"
                  className={classes.pointIcon}
                  alt="donate"
                />
              </Grid>
              <Grid
                item
                xl={10}
                alignItems={"center"}
                className={classes.pointText}
              >
                <span>
                  The cost of the repairs is sponsored by generous donors.
                </span>
              </Grid>
            </Grid>
            <Grid container xl={12} spacing={1} className={classes.point}>
              <Grid
                item
                xl={2}
                className={classes.pointIconParent}
                alignItems={"right"}
              >
                <img
                  height="px"
                  src="/points/connect.png"
                  className={classes.pointIcon}
                  alt="connect"
                />
              </Grid>
              <Grid
                item
                xl={10}
                alignItems={"center"}
                className={classes.pointText}
              >
                <span>
                  Kandua's platform facilitates the connections &amp; repairs
                  without charge
                </span>
              </Grid>
            </Grid>
          </div>

          <h2>Can you help?</h2>
          <p>Yes. 🙏.</p>
          <p>
            For those of us with financial means:{" "}
            <a
              className={classes.helpLink}
              href="#donation"
              onClick={handleShowForm}
            >
              Donate here
            </a>
            .<br />
            For those of us with networks:{" "}
            <a
              className={classes.helpLink}
              href="#share"
              onClick={handleShowShare}
            >
              Share here
            </a>
            .<br />
            For those of us with ideas:{" "}
            <a
              className={classes.helpLink}
              href={
                "mailto:rebuild@kandua.com?" +
                qs.stringify({
                  subject: "I'd like to collaborate!",
                  body: "Hi Kandua,\n",
                })
              }
            >
              Collaborate here
            </a>
            .
          </p>

          <h2>Do you need help?</h2>
          <p>
            If your business property was damaged during the unrest, you may be
            eligible for free structural repairs.
            <br />
            First in line will be <emph>small businesses</emph> providing{" "}
            <emph>key infrastructure</emph> to their communities.
            <br /> Great examples of businesses like this are:
            <ul>
              <li>The AlexFM radio station that was burnt down</li>
              <li>A local pharmacy that was destroyed</li>
            </ul>
          </p>

          <Button
            className={classes.apply}
            color="primary"
            variant="contained"
            boxShadow={2}
            target="_blank"
            href="https://kandua.typeform.com/to/u7B94KK1"
          >
            Apply for small business repairs
          </Button>

          <h2 className={classes.detailsHeading}>What are the details?</h2>
          <h3>How does repair costing work?</h3>

          <p>
            Services providers will charge fair market rates to conduct the
            repairs, as ascertained by{" "}
            <a
              href="https://kandua.com"
              style={{ color: "#4c95fc" }}
              target="_blank"
            >
              Kandua
            </a>{" "}
            using historic data from over 100,000 jobs, soliciting multiple
            quotes where necessary.
          </p>

          <p>Kandua will not add any surcharge for facilitating this.</p>

          <h3>How will businesses be selected?</h3>

          <p>
            We will rebuild as many businesses as we can.
            <br />
            Businesses will be carefully prioritised based on the following 2
            criteria:
          </p>

          <ol className={classes.criteria}>
            <li className={classes.criterion}>
              How critical is the business to satisfying immediate needs of a
              community?
              <br />
              &emsp;
              <i>
                <span className={classes.example}>
                  E.g: Food is more critical than jewellery{" "}
                </span>
              </i>
            </li>

            <li className={classes.criterion}>
              To what extent is the business able to recover without support?
              <br />
              &emsp;
              <i>
                <span className={classes.example}>
                  E.g: A national chain can recover more easily than a spaza
                  shop{" "}
                </span>
              </i>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}

export default App;
