import { makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  donateContainer: {
    // fontSize: theme.typography.pxToRem(35),
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(3),
    // margin: theme.spacing(2),
    // borderStyle: "solid",
    // borderColor: theme.palette.primary.main,
    // borderWidth: "5px",
    // borderRadius: theme.spacing(1),
    backgroundColor: "#eeeeee",
  },

  donateAmount: {
    fontSize: '30px'
  },

  donateTitle: {
    fontWeight: '400',
    fontSize: '20px'
  }
}));

function DonateProgress(props) {
  const classes = useStyles(props);
  const formattedAmount = props.amount.toLocaleString('en-US', {
    minimumFractionDigits: 2,
  });

  return (
    <Box className={classes.donateContainer} id="donation">
     {/* <div className={classes.donateTitle}> An important start</div> */}
     <div className={classes.donateAmount}>
      Raised so far: R{formattedAmount}
      </div>
      <div className={classes.donateTitle}> For businesses in need </div>
    </Box>
  )
}

export default DonateProgress;