import {
  makeStyles,
  withStyles,
  Fab,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  InputAdornment,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { useEffect, useState, Fragment } from "react";
import QS from "qs";

// const theme = createTheme({
//   overrides: {
//     // Style sheet name ⚛️
//     MuiButton: {
//       // Name of the rule
//       text: {
//         // Some CSS
//         background: 'linear-gradient(45deg, #FE6B8B 30%, #4c95fc 90%)',
//         // borderRadius: 3,
//         // border: 0,
//         color: 'white',
//         // backgroundColor: '#4c95fc'
//         // height: 48,
//         // padding: '0 30px',
//         // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
//       },
//     },
//   },
// });

const useStyles = makeStyles((theme) => ({
  button: {
    position: "fixed",
    zIndex: "10001",
    top: "auto",
    left: "auto",
    bottom: "20px",
    right: "20px",
    color: "#FFFFFFF",
    fontSize: theme.typography.pxToRem(30),
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: 5,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    margin: theme.spacing(2),
  },
  donateHeader: {
    backgroundColor: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    "& .MuiTypography-h6": {
      fontSize: theme.typography.pxToRem(30),
      fontWeight: 600,
    },
  },
  donateConfirm: {
    margin: theme.spacing(2),
    "& .MuiTypography-root": {
      fontSize: theme.typography.pxToRem(22),
    },
  },
  textfield: {
    width: "100%",
    color: "black",
    borderColor: "red",
    marginBottom: theme.spacing(1),
    "& .MuiTypography-root": {
      color: "black",
    },
    "& .MuiTypography-body1": {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  textfieldFont: {
    fontSize: theme.typography.pxToRem(18),
  },
  submitButton: {
    textTransform: "none",
    fontSize: theme.typography.pxToRem(18),
  },
  tooltipIcon: {
    fontStyle: "italic",
    textAlign: "center",
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f9f9f9",
    color: "black",
    fontSize: theme.typography.pxToRem(14),
    maxWidth: "500px",
  },
}))(Tooltip);

function DonateButton(props) {
  const classes = useStyles(props);
  const { showForm, setShowForm } = props;
  const { showShare } = props;
  const [anonymous, setAnonymous] = useState(false);

  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [amount, setAmount] = useState(0);

  const [disableSubmit, setDisableSubmit] = useState(true);
  const [paySuccess, setPaySuccess] = useState(false);

  // setAnonymous(false);

  useEffect(() => {
    const search = QS.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    if (search.donateSuccess) {
      setPaySuccess(true);
    }
  }, []);

  const openDonateForm = () => {
    setShowForm(true);
  };

  const closeDonateForm = () => {
    setShowForm(false);
  };

  const anonymousSwitched = (event) => {
    setAnonymous(event.target.checked);
  };

  const handleNameChanged = (event) => {
    setName(event.target.value);
  };

  const validateName = () => {
    return name.length > 0 || anonymous;
  };

  const handleAmountChanged = (event) => {
    try {
      const amount = parseFloat(event.target.value);
      setAmount(amount);
    } catch {
      setAmount(0);
    }
  };

  const validateAmount = () => {
    return typeof amount === "number" && amount > 0;
  };

  const handleMessageChanged = (event) => {
    setMessage(event.target.value);
  };

  const validateMessage = () => {
    return true;
  };

  useEffect(() => {
    const updateSubmitDisabled = () => {
      setDisableSubmit(
        !validateName() || !validateAmount() || !validateMessage()
      );
    };

    updateSubmitDisabled();
    // eslint-disable-next-line
  }, [name, anonymous, amount, message]);

  // for debugging.

  return (
    <div>
      {!showForm && !showShare ? (
        <Fab
          className={classes.button}
          color="primary"
          variant="extended"
          onClick={openDonateForm}
          hidden={true}
        >
          Donate
        </Fab>
      ) : null}
      {paySuccess ? (
        <Dialog open={paySuccess} onClose={() => setPaySuccess(false)}>
          <DialogTitle className={classes.donateHeader}>Thank you!</DialogTitle>
          <DialogContent className={classes.donateConfirm}>
            <Typography variant="body" className={classes.donateConfirmText}>
              We've received your donation, and you'll now show up on the donor
              list. You're great. Please consider sharing this initiative with
              friends.{" "}
            </Typography>
          </DialogContent>
        </Dialog>
      ) : null}
      {showForm ? (
        <Dialog open={showForm} onClose={closeDonateForm}>
          <DialogTitle className={classes.donateHeader}>Donate</DialogTitle>
          <DialogContent>
            <TextField
              className={classes.textfield}
              type="text"
              onChange={handleNameChanged}
              value={name}
              placeholder="Your name"
              variant="outlined"
              fullWidth
              InputProps={{ classes: { input: classes.textfieldFont } }}
              inputProps={{ maxLength: 128 }}
            ></TextField>
            <TextField
              className={classes.textfield}
              type="number"
              onChange={handleAmountChanged}
              value={amount}
              placeholder="Your donation"
              variant="outlined"
              color="primary"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className={classes.adornment}
                  >
                    R
                  </InputAdornment>
                ),
                classes: { input: classes.textfieldFont },
              }}
            ></TextField>
            <TextField
              className={classes.textfield}
              type="text"
              onChange={handleMessageChanged}
              value={message}
              multiline
              rows={6}
              placeholder="[Optional] Your message"
              variant="outlined"
              fullWidth
              InputProps={{ classes: { input: classes.textfieldFont } }}
              inputProps={{ maxLength: 256 }}
            ></TextField>
            <div className={classes.tooltipIcon}>
              <HtmlTooltip
                interactive
                enterTouchDelay={0}
                leaveTouchDelay={60000}
                title={
                  <Fragment className={classes.tooltip}>
                    <Typography variant="h6">Message Guidelines</Typography>
                    <Typography variant="body">
                      When adding your message, please follow the guidelines
                      below. If your message is deemed unfit, we reserve the
                      right to redact it, in the ethos of the initiative. Your
                      message...
                    </Typography>
                    <ul>
                      <li>should not contain political comments</li>
                      <li>
                        should not contain racism, sexism, or any other form
                        discrimination
                      </li>
                      <li>should not contain any form of hate speech</li>
                      <li>
                        should not contain any form of incitement of violence or
                        harmful action
                      </li>
                    </ul>
                    <Typography variant="body">
                      Only together can we #rebuildSA!
                    </Typography>
                  </Fragment>
                }
              >
                <InfoOutlined />
              </HtmlTooltip>
            </div>
          </DialogContent>
          <DialogActions>
            <FormControlLabel
              control={
                <Switch
                  checked={anonymous}
                  onChange={anonymousSwitched}
                  color="primary"
                />
              }
              label="Anonymous"
              style={{ paddingRight: "30px" }}
            />
            <form action="https://www.payfast.co.za/eng/process" method="POST">
              <input type="hidden" name="merchant_id" value="10054771" />
              <input type="hidden" name="merchant_key" value="1ekjf3qpginxd" />
              <input
                type="hidden"
                name="return_url"
                value="https://rebuild.kandua.com/?donateSuccess=true"
              />
              <input
                type="hidden"
                name="notify_url"
                value="https://rebuild-api.kandua.com/payfast-payment-notification"
              />
              <input type="hidden" name="name_first" value={name} />
              <input type="hidden" name="email_address" value="" />
              <input type="hidden" name="amount" value={amount} />
              <input
                type="hidden"
                name="item_name"
                value="Kandua Rebuild Donation"
              />
              <input type="hidden" name="custom_str1" value={message} />
              <input type="hidden" name="custom_str2" value={anonymous} />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submitButton}
                disabled={disableSubmit}
              >
                Donate
              </Button>
            </form>
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  );
}

export default DonateButton;
