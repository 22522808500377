import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/styles';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme } from '@material-ui/core';
import poppins from 'typeface-poppins';


const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif'
  },
  palette: {
    primary: {
      main: "#4c95fc",
      contrastText: '#fff',
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [poppins],
      },
    },
    // Style sheet name ⚛️
    // MuiButton: {
    //   // color: 'white',
    //   // Name of the rule
    //   text: {
    //     // Some CSS
    //     // background: 'linear-gradient(45deg, #FE6B8B 30%, #4c95fc 90%)',
    //     // borderRadius: 3,
    //     // border: 0,
    //     // color: 'white',
    //     // backgroundColor: '#4c95fc'
    //     // height: 48,
    //     // padding: '0 30px',
    //     // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    //   },
    // },
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

console.log("sadlol");
