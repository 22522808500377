import {
  makeStyles,
  withStyles,
  Fab,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  InputAdornment,
  Typography,
  Tooltip,
} from "@material-ui/core";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { useEffect, useState, Fragment } from "react";
import { InfoOutlined, Twitter } from "@material-ui/icons";
import qs from "qs";

const useStyles = makeStyles((theme) => ({
  shareHeader: {
    backgroundColor: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    "& .MuiTypography-h6": {
      fontSize: theme.typography.pxToRem(30),
      fontWeight: 600,
    },
  },
  shareContent: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  shareButtons: {
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  shareButton: {
    width: "120px",
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function ShareDialog(props) {
  const classes = useStyles(props);
  const { showDialog, setShowDialog } = props;
  const url = "https://rebuild.kandua.com";
  const shortQuote =
    "Help Kandua raise funding to repair small businesses and key community services affected by the recent unrest! Join me in donating today to #rebuildSA with Kandua.";
  const longQuote = `
  The recent unrest has left many of our communities with immeasurable damage to businesses, livelihoods and peoples wellbeing. To help our communities, Kandua is supporting the #rebuildSA campaign to help repair businesses and community services that have been damaged. Selected businesses will receive funding for materials and support from Kandua Pros to restore the key services in our neighbourhoods.
  
  Help Kandua raise funding to repair small businesses and key community services affected by the recent unrest! #rebuildSA with Kandua.`;

  const closeDialog = () => {
    setShowDialog(false);
  };

  return (
    <Fragment>
      <div>
        {showDialog ? (
          <Dialog open={showDialog} onClose={closeDialog}>
            <DialogTitle className={classes.shareHeader}>Share</DialogTitle>
            <DialogContent className={classes.shareContent}>
              <div className={classes.shareButtons}>
                <EmailShareButton
                  url={url}
                  subject={"Rebuild SA with Kandua"}
                  body={longQuote}
                  className={classes.shareButton}
                >
                  <EmailIcon round={true} />
                  Email
                </EmailShareButton>
                <FacebookShareButton
                  url={url}
                  quote={shortQuote}
                  hashtag={"#rebuildSA"}
                  className={classes.shareButton}
                >
                  <FacebookIcon round={true} />
                  Facebook
                </FacebookShareButton>
                <TwitterShareButton
                  url={url}
                  title={shortQuote}
                  hashtags={["rebuildSA"]}
                  className={classes.shareButton}
                >
                  <TwitterIcon round={true} />
                  Twitter
                </TwitterShareButton>
                <WhatsappShareButton
                  url={url}
                  title={shortQuote}
                  className={classes.shareButton}
                >
                  <WhatsappIcon round={true} />
                  WhatsApp
                </WhatsappShareButton>
              </div>
            </DialogContent>
          </Dialog>
        ) : null}
      </div>
    </Fragment>
  );
}

export default ShareDialog;
